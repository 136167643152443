import error from './zh-CN/error';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';
import button from './zh-CN/button';
import placeholder from './zh-CN/placeholder';
import validmessage from './zh-CN/validmessage';
import tipmessage from './zh-CN/tipmessage';

const zhCN = {
  'common.loading': '正在处理中...',
  'layout.appname': 'Digital Platform for Express Service',
  'layout.lang': '多语言',
  'layout.signout': '退出',
  'modal.title': '系统消息',
  'system.notice': '通知公告',
  'system.draftbox': '我的草稿箱',
  ...error,
  ...menu,
  ...pages,
  ...button,
  ...placeholder,
  ...validmessage,
  ...tipmessage,
};
export default zhCN;
