import error from './en-US/error';
import menu from './en-US/menu';
import pages from './en-US/pages';
import button from './en-US/button';
import placeholder from './en-US/placeholder';
import validmessage from './en-US/validmessage';
import tipmessage from './en-US/tipmessage';

const enUS = {
  'common.loading': 'Loading...',
  'layout.appname': 'Digital Platform for Express Service',
  'layout.lang': 'Languages',
  'layout.signout': 'Sign Out',
  'modal.title': 'System Message',
  'system.notice': 'Notice',
  'system.draftbox': 'My draft box',
  ...error,
  ...menu,
  ...pages,
  ...button,
  ...placeholder,
  ...validmessage,
  ...tipmessage,
};
export default enUS;
