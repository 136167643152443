const messages = {
    'v.input.required': 'The input value cannot be empty',
    'v.email.required': 'Email cannot be empty',
    'v.select.required': 'The selection value cannot be empty',
    'v.input.max9': 'Text cannot exceed 9 characters',
    'v.input.max10': 'Text cannot exceed 10 characters',
    'v.input.max16': 'Text cannot exceed 16 characters',
    'v.input.max17': 'Text cannot exceed 17 characters',
    'v.input.max18': 'Text cannot exceed 18 characters',
    'v.input.max20': 'Text cannot exceed 20 characters',
    'v.input.max25': 'Text cannot exceed 25 characters',
    'v.input.max35': 'Text cannot exceed 35 characters',
    'v.input.max40': 'Text cannot exceed 40 characters',
    'v.input.max50': 'Text cannot exceed 50 characters',
    'v.input.max100': 'Text cannot exceed 100 characters',
    'v.input.max200': 'Text cannot exceed 200 characters',
    'v.input.max250': 'Text cannot exceed 250 characters',
    'v.input.max500': 'Text cannot exceed 500 characters',
    'v.input.max2000': 'Text cannot exceed 2000 characters',
    'v.phone.formaterror': 'Phone format error',    
    'v.remark.isnotnull': 'Remarks cannot be empty',
  };
  export default messages;
  