const messages = {
    'masterdata.add.success': 'Master data added successfully!',
    'masterdata.edit.success': 'Master data edited successfully!',
    'masterdata.delete.success': 'Master data deleted successfully!',    
    'emailtemplate.add.success': 'Email template edded successfully!',
    'emailtemplate.edit.success': 'Email template edited successfully!',
    'emailtemplate.delete.success': 'Email template deleted successfully!',    
    'system.leavewithoutsaving.title': 'System Notice',
    'system.leavewithoutsaving.content': 'If you leave the current page, unsaved data will be lost. Are you sure you want to leave?',    
    'carriers.add.success': 'Carriers added successfully!',
    'carriers.edit.success': 'Carriers edited successfully!',
    'carriers.delete.success': 'Carriers deleted successfully!', 
    'accounts.add.success': 'Accounts added successfully!',
    'accounts.edit.success': 'Accounts edited successfully!',
    'accounts.delete.success': 'Accounts deleted successfully!', 
    'freight.updatestauts.success':'Update status successfully!',
    'waybill.updatestatus.success':'Update print status successfully!',
    'freight.upload.success':'Upload successfully!',
    'freight.sendtodppo.success':'Send to DPPO successfully!',
    'freight.getdhlbilling.success':"Get DHL Billing successfully!",
    'freight.getdhlbilling.fail':"Get DHL Billing failed!",
    'freight.export.fail':'Export failed!',
    'freight.senddppo.fail': "Send to PO failed!",
    'waybill.getprintwaybilldocument.success':'Get Print WaybillDocument successfully!'
  };
  export default messages;
  