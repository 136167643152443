const messages = {
  'p.login.error':
    "Something went wrong and we can't sign you in right now. Please try again.",
  'p.login.welcome': 'Welcome to Cuscare Portal',
  'p.login.employee': 'ABB Employee',
  'p.login.employeetip': 'Sign in with ABB email',
  'p.login.customer': 'ABB Customer',
  'p.login.customertip': 'Query my orders',
  'p.errorpage.403': 'FORBIDDEN',
  'p.errorpage.404': 'PAGE NOT FOUND',
  'p.errorpage.home': 'BACK TO HOME PAGE',
  'p.table.action': 'Actions',
  'p.table.lastupdatedby': 'Last Updated By',
  'p.table.lastupdatedtime': 'Last Updated Time',
  'p.table.remark': 'Remark',
  'p.masterdata.type': 'Data Type',
  'p.masterdata.text': 'Data Text',
  'p.masterdata.value': 'Data Value',
  'p.masterdata.mappingkey': 'Mapping Key',
  'p.masterdata.mappingkey2': 'Mapping Key2',
  'p.masterdata.IsEnabled': 'Is Enabled',
  'p.masterdata.displayindex': 'Display Index',
  'p.masterdata.createtitle': 'Create',
  'p.masterdata.edittitle': 'Update',
  'p.action.deleteconfirmtitle': 'Are you sure you want to delete this data?',  
  'p.emailtemplate.classify': 'Classify',
  'p.emailtemplate.name': 'Name',
  'p.emailtemplate.code': 'Code',
  'p.emailtemplate.subjecttemplate': 'Subject',
  'p.emailtemplate.bodytemplate': 'Content',
  'p.emailtemplate.createtitle': 'Create',
  'p.emailtemplate.edittitle': 'Update',  
  'p.table.createdby': 'Created By',
  'p.table.createdtime': 'Created Time',
  'p.carriers.no':'No.',
  'p.carriers.shippingtype':'Shipping Type',
  'p.carriers.name':'Name',
  'p.carriers.code':'Code',
  'p.carriers.carriername':'Carrier Name',
  'p.carriers.carriercode':'Carrier Code',
  'p.carriers.account':'Account',
  'p.carriers.password':'Password',
  'p.carriers.isenabled':'Is Enabled',
  'p.carriers.updatedate':'Update Date',
  'p.carriers.createtitle': 'Create',
  'p.carriers.edittitle': 'Update',
  'p.paymentaccount.title':'Payment Account List',
  'p.paymentaccount.no':'No.',
  'p.paymentaccount.companyname':'Company Name',
  'p.paymentaccount.companycode':'Company Code',
  'p.paymentaccount.name':'Name',
  'p.paymentaccount.number':'Number',
  'p.paymentaccount.isenabled':'Is Enabled',
  'p.paymentaccount.updatedate':'Update Date',
  'p.paymentaccount.createtitle': 'Create',
  'p.paymentaccount.edittitle': 'Update',
  'p.carrieraccount.title':'Carrier Account List',
  'p.carrieraccount.no':'No.',
  'p.carrieraccount.companyname':'Company Name',
  'p.carrieraccount.companycode':'Company Code',
  'p.carrieraccount.account':'Account',
  'p.carrieraccount.password':'Password',
  'p.carrieraccount.isenabled':'Is Enabled',
  'p.carrieraccount.updatedate':'Update Date',
  'p.carrieraccount.createtitle': 'Create',
  'p.carrieraccount.edittitle': 'Update',
  'p.porequest.porequestno':'PO Request No.',
  'p.porequest.requestor':'Requestor',
  'p.porequest.requestdate':'Request Date',
  'p.porequest.carrier':'Carrier',
  'p.porequest.company':'Company',
  'p.porequest.totalamount':'Total Amount',
  'p.porequest.currency':'Currency',
  'p.porequest.costobject':'Cost Object',
  'p.porequest.billingmonth':'Month of Billing',
  'p.porequest.invoiceno':'Pre-invoice No.',
  'p.porequest.trackingno':'Tracking No',
  'p.porequest.shippercity':'Shipper City',
  'p.porequest.receivercity':'Receiver City',
  'p.porequest.monthlycard':'Monthly Card',
  'p.porequest.monthlyweight':'Monthly Weight',
  'p.porequest.amount':'Amount',
  'p.shipments.dnno':'DN No.',
  'p.shipments.status':'Status',
  'p.shipments.trackingno':'Tracking No.',
  'p.shipments.tcpno':'TCP No',
  'p.shipments.type':'Type',
  'p.shipments.shipmentcreatedate':'Shipment Create Date',
  'p.shipments.submittedby':'Submitted by',
  'p.shipments.carrier':'Carrier',
  'p.shipments.company':'Company',
  'p.shipments.deliverydate':'Delivery Date',
  'p.shipments.basicinformation':'Basic Information',
  'p.shipments.dn':'DN',
  'p.shipments.submitdate':'Submit Date',
  'p.shipments.pricerule':'Price Rule',
  'p.shipments.creator':'Creator',
  'p.shipments.pg':'PG',
  'p.shipments.mobile':'Mobile',
  'p.shipments.transportationtype':'Transportation Type',
  'p.shipments.plantcode':'Plant Code',
  'p.shipments.costobject':'Cost Object',
  'p.shipments.declaredvalue':'Declare Value',
  'p.shipments.unitofmeasurement':'Unit of Measurement',
  'p.shipments.currency':'Currency',
  'p.shipments.incoterm':'Incoterm',
  'p.shipments.shipperdetails':'Shipper Details',
  'p.shipments.countrycode':'Country Code',
  'p.shipments.province':'Province',
  'p.shipments.city':'City',
  'p.shipments.postalcode':'Postal Code',
  'p.shipments.address':'Address',
  'p.shipments.contract':'Contact',
  'p.shipments.companyname':'Company Name',
  'p.shipments.tel':'Tel',
  'p.shipments.email':'eMail',
  'p.shipments.receiverdetails':'Receiver Details',
  'p.shipments.cargodetails':'Cargo Details',
  'p.shipments.huno':'HU No.',
  'p.shipments.length':'Length',
  'p.shipments.width':'Width',
  'p.shipments.height':'Height',
  'p.shipments.weight':'Weight',
  'p.shipments.itemno':'Item No.',
  'p.shipments.count':'Count',
  'p.shipments.materialdescription':'Material Description',
  'p.shipments.material':'Material',
  'p.shipments.serialno':'Serial No.',
  'p.shipments.price':'Price',
  'p.shipments.quantity':'Quantity',
  'p.shipments.hscode':'HS Code',
  'p.shipments.itemdetails':'Item Details',
  'p.shipments.duties':'Duties',
  'p.shipments.ordercreatedate':'Shipment Order Create Date',
  'p.freight.uploadbillingfile':'Upload Billing File',
  'p.freight.trackingno':'Tracking No.',
  'p.freight.invoiceno':'Pre-invoice No.',
  'p.freight.shippercity':'Shipper City',
  'p.freight.receivercity':'Receiver City',
  'p.freight.dn':'DN',
  'p.freight.billingmonth':'Month of Billing',
  'p.freight.company':'Company',
  'p.freight.monthlycard':'Monthly Card',
  'p.freight.amount':'Amount',
  'p.freight.currency':'Currency',
  'p.freight.freightbilldate':'Date',
  'p.freight.receivercompany1':'Receiver Company 1',
  'p.freight.sfexrepresstype':'SF Exrepress Type',
  'p.freight.paymentmethod':'Payment Method',
  'p.freight.freight':'Freight',
  'p.freight.discount':'Discount',
  'p.freight.charge':'Charge',
  'p.freight.shippername1':'Shipper Name 1',
  'p.freight.servicetype':'Service Type',
  'p.freight.shippercompany':'Shipper Company',
  'p.freight.shippertel':'Shipper Tel',
  'p.freight.receivercompany2':'Receiver Company 2',
  'p.freight.receivertel':'Receiver Tel',
  'p.freight.shippername2':'Shipper Name 2',
  'p.freight.delverydate':'Delvery Date',
  'p.freight.originprovince':'Origin Province',
  'p.freight.shippercompanyaddress':'Shipper Company Address',
  'p.freight.sfreceiverno':'SF Receiver No.',
  'p.freight.commodityname':'Commodity Name',
  'p.freight.declarevalue':'Declare Value',
  'p.freight.pcs':'PCS',
  'p.freight.volume':'Volume',
  'p.freight.destinationprovince':'Destination Province',
  'p.freight.receivername':'Receiver Name',
  'p.freight.receiveraddress':'Receiver Address',
  'p.freight.sfdispatcherno':'SF Dispatcher No.',
  'p.freight.auturalweight':'Actural Weight ',
  'p.freight.signbacktrackingno':'Signback Tracking No.',
  'p.freight.receivedby':'Received by',
  'p.freight.receivetime':'Receive  Time',
  'p.freight.returnrelatedtrackingno':'Return related Tracking No.',
  'p.freight.extention1':'Extention 1',
  'p.freight.extention2':'Extention 2',
  'p.freight.extention3':'Extention 3',
  'p.freight.branchcompanyaccount':'Branch Company Account',
  'p.freight.orderno':'Order No.',
  'p.freight.remark':'Remark',
  'p.freight.sfexpress':'SF Express ',
  'p.freight.countrydistrict':'Country/District',
  'p.freight.regiontype':'Region Type',
  'p.freight.length':'Length',
  'p.freight.width':'Width',
  'p.freight.height':'Height',
  'p.freight.status':'Status',
  'p.freight.account':'Account Number',
  'p.freight.accountnumber':'Invoice Number',
  'p.freight.trackingnumber':'Tracking Number',
  'p.freight.shipmentdate':'Ship Date',
  'p.freight.shippercountrycode':'Origin Country',
  'p.freight.shipperthreecharactercode':'Origin Port',
  'p.freight.shippercityname':'Origin City',
  'p.freight.receivercountrycode':'DES Country',
  'p.freight.receiverthreecharactercode':'Destination Port',
  'p.freight.receivercityname':'Destination City',
  'p.freight.shipperreferenceinformation':'Shipper Ref Number',
  'p.freight.shipperdisplayname':'Shipper Contact',
  'p.freitht.shippercompany':'Shipper Name',
  'p.freight.shipperdddress1':'Shipper Address 1',
  'p.freight.shipperdddress2':'Shipper Address 2',
  'p.freight.shipperpostalcode':'Origin Postal Code',
  'p.freight.receiverdisplayname':'Consignee Contact',
  'p.freight.receivercompany':'Consignee Name',
  'p.freight.receiveraddress1':'Consignee Address 1',
  'p.freight.receiverAddress2':'Consignee Address 2',
  'p.freight.receiverpostalcode':'Destination Postal Code',
  'p.freight.quantity':'Lading Quantity',
  'p.freight.billingweight':'Chargeable weight',
  'p.freight.goodsvalue':'Cargo Value',
  'p.freight.basecharge':'Main freight',
  'p.freight.fuelsurcharge':'Fuel Surcharge',
  'p.freight.remoteareadeliverycharge':'Remote Area P/D',
  'p.freight.overweightpricecharge':'Oversized Goods',
  'p.freight.nonstackablepalletcharge':'Non-stackable Goods',
  'p.freight.addresscorrectioncharge':'Address Correction',
  'p.freight.expressvalueinsurancecharge':'Premium',
  'p.freight.otherscharge':'Other Charges',
  'p.freight.shipperpaylandtarifftax':'Duty/Tax Paid by Shipper',
  'p.freight.documentprotection':'Document Fee',
  'p.freight.emergencysurcharge':'Emergency Surcharge',
  'p.freight.addedtax':'VAT',//增值税
  'p.freight.totalamount':'Total Amount',
  'p.freight.expensecode':'Expense Code',
  'p.freight.expensedescription':'Expense Description',
  'p.print.dn':'DN',
  'p.print.trackingno':'Tracking No',
  'p.print.status':'Status',
  'p.print.createtime':'Create Time',
  'p.print.lastprinttime':'Last Print Time',
  'p.print.carrier':'Carrier',
  'p.print.shippercity':'Shipper City',
  'p.print.receivercity':'Receiver City',
  'p.print.waybilldoc':'Waybill doc.',
  'p.print.warehouse':'Warehouse',
  'p.action.cancelconfirmtitle':'Cancel this shipment order, you will not have the chance to print the Waybill Doc any more.Please confirm the right doc has been printed or DN packages have been picked up. Continue to Cancel?',
  'p.action.printconfirmtitle':'Print this Waybill Doc, you will cancel the previous shipment order. Continue to print?',
  'p.action.printconfirmtitle2':'This DN has more than one Tracking No. Please check information before you print.',
  'p.action.marknotpaytitle':'This record will be set to Pending Check and not be allowed to send to DPPO. Continue this action?',
  'p.action.sendallpotitle':'This will send all the approved freight items of {BillingMonth} (ready to send) to DPPO?'
};
export default messages;
