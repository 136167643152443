const messages = {
  'menu.print': 'Print',
  'menu.freight': 'Freight',
  'menu.report': 'Reports',  
  'menu.settings':'Settings',
  'menu.systemmanagement': 'System Management',
  'menu.masterdata': 'Master Data Management', 
  'menu.emailtemplate': 'Email Template Management',
  'menu.carriers': 'Carriers',
  'menu.paymentaccount': 'Payment Account',
  'menu.carrieraccount':'Carrier Account',
  'menu.porequest':'PO Request',
  'menu.shipments':'Shipments',
  'menu.details':'Details',
  'menu.dninformation':'DN Information',
  'menu.exportdeclarationinformation':'Export Declaration Information',
  'menu.shipmentinformation':'Shipment Information',
  'menu.freightinformation':'Freight Information'
};
export default messages;
