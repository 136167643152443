import React from 'react';
import { Menu, Divider } from 'antd';
import { UserOutlined, ExportOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectMenus, selectName, signOut } from '../../features/auth/authSlice';
import { Link } from 'react-router-dom';
import './LayoutDefault.less';
import { FormattedMessage, useIntl } from 'react-intl';

function LayoutDefault({ children, menu }) {
  let year = new Date().getFullYear();
  return (
    <div className="layout-default">
      <div className="app-header-container">
        <div className="app-header-wrapper">
          <div className="abb-bar">
            <FormattedMessage id="layout.appname" />
          </div>
          <div className="app-header-bar">
            <Navbar currentMenu={menu} />
            <Toolbar />
          </div>
        </div>
      </div>
      <div className="app-body-container">
        <div className="app-body-wrapper">{children}</div>
      </div>
      <div className="app-footer-container">
        <div className="app-footer-wrapper">&copy; {year} ABB</div>
      </div>
    </div>
  );
}

export default LayoutDefault;

// child component
function Navbar({ currentMenu }) {
  // component
  const { SubMenu } = Menu;
  // selector
  const menus = useSelector(selectMenus);
  const intl = useIntl();

  function getNavItems(items) {
    return items.map(x =>
      x.children && x.children.length > 0 ? (
        <SubMenu
          key={x.menuCode}
          title={intl.formatMessage({
            id: `menu.${x.menuCode}`,
            defaultMessage: x.menuName,
          })}
        >
          {getNavItems(x.children)}
        </SubMenu>
      ) : (
        <Menu.Item key={x.menuCode}>
          {/^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/.test(x.url) ? (
            <a target="_blank" href={x.url} rel="noreferrer">
              {x.menuName}
            </a>
          ) : (
            <Link to={x.url}>
              <FormattedMessage
                id={`menu.${x.menuCode}`}
                defaultMessage={x.menuName}
              />
            </Link>
          )}
        </Menu.Item>
      )
    );
  }

  return (
    <Menu className="topmenus" selectedKeys={[currentMenu]} mode="horizontal">
      {getNavItems(menus)}
    </Menu>
  );
}

function Toolbar() {
  const dispatch = useDispatch();
  const name = useSelector(selectName);
  const handleLogout = () => {
    dispatch(signOut());
    window.location.href = window.ENV_CFG.signout;
  };
  return (
    <div className="app-header-bar-controls">
      {/* <div className="control-item">
        <LanguageSetting className="lang" />
      </div> */}
      <div className="control-item">
        <UserOutlined />
        {name}
      </div> 
      <Divider type="vertical" />
      <div className="control-item" onClick={handleLogout}>
        <ExportOutlined />
      </div> 
    </div>
  );
}
