import { lazy } from 'react';
//import SideMenu from '../components/SideMenu';
import LayoutDefault from '../components/layout/LayoutDefault';

// auth: 验证权限
// menu: 菜单，如果为空与auth一致
// anonymous: 默认false
// layout: 默认LayoutDefault
// exact: 是否精确匹配
// routes: 子路由
const routes = [
  {
    auth: 'print',
    path: '/waybill/toprint',
    component: lazy(() => import('../features/waybill/toprint/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'freight',
    path: '/freight',
    component: lazy(() => import('../features/freight/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'freight',
    path: '/freight/sffreightbilldetail/:freightBillID',
    component: lazy(() => import('../features/freight/detail/sffreightbilldetail')),
    layout: LayoutDefault,
  },
  {
    auth: 'freight',
    path: '/freight/dhlfreightbilldetail/:freightBillID',
    component: lazy(() => import('../features/freight/detail/dhlfreightbilldetail')),
    layout: LayoutDefault,
  },
  {
    auth: 'shipmentsreport',
    path: '/shipments/report',
    component: lazy(() => import('../features/shipments/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'porequestreport',
    path: '/porequest/report',
    component: lazy(() => import('../features/porequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'porequestdetail',
    path: '/porequestdetail/:id',
    component: lazy(() => import('../features/porequest/detail')),
    layout: LayoutDefault,
  },
  {
    auth: 'shipmentsreport',
    path: '/shipments/report',
    component: lazy(() => import('../features/shipments/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'dninfo',
    path: '/shipments/dninfo/:id',
    component: lazy(() => import('../features/shipments/dninfo')),
    layout: LayoutDefault,
  },
  {
    auth: 'shippinginfo',
    path: '/shipments/shippinginfo/:waybillid',
    component: lazy(() => import('../features/shipments/shippinginfo')),
    layout: LayoutDefault,
  },
  {
    auth: 'exportdeclarationinfo',
    path: '/shipments/exportdeclarationinfo/:deliveryNoteId',
    component: lazy(() => import('../features/shipments/exportdeclarationinfo')),
    layout: LayoutDefault,
  },
  // {
  //   auth: 'print',
  //   path: '/waybill/waybilldocument/',
  //   component: lazy(() => import('../features/waybill/toprint/waybilldocumentprint')),
  //   anonymous: true,
  //   layout: false,
  // },
  {
    auth: 'carriers',
    path: '/carriers',
    component: lazy(() => import('../features/carriers/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'carrieraccount',
    path: '/carrieraccount/:carrierID',
    component: lazy(() => import('../features/carrieraccount/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'paymentaccount',
    path: '/paymentaccount/:carrierID',
    component: lazy(() => import('../features/paymentaccount/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'masterdata',
    path: '/masterdata',
    component: lazy(() => import('../features/masterdata/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'emailtemplate',
    path: '/emailtemplate',
    component: lazy(() => import('../features/emailtemplate/index')),
    layout: LayoutDefault,
  },
  {
    path: '/signin',
    component: lazy(() => import('../features/auth/SignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/signin-internal-use-only',
    component: lazy(() => import('../features/auth/SignInTest')),
    anonymous: true,
    layout: false,
  },
];

function standardize(items) {
  for (let item of items) {
    // menu
    if (item.auth && !item.menu) item.menu = item.auth;
    // anonymous
    item.anonymous = item.anonymous === true ? true : false;
    // exact
    if (item.exact === undefined)
      item.exact = !(item.routes && item.routes.length > 0);
    if (item.routes) standardize(item.routes);
  }
}

standardize(routes);

export default routes;
