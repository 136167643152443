import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Spin } from 'antd';
import LayoutDefault from '../components/layout/LayoutDefault';
import RedirectDefault from '../components/RedirectDefault';
import HttpError from '../components/httperror/HttpError';
import routes from './routes';
import {
  AUTH_STATUS,
  selectPermission,
  selectStatus,
} from '../features/auth/authSlice';
import { useIntl } from 'react-intl';

function AppRoute({ route }) {
  const status = useSelector(selectStatus);
  const perms = useSelector(selectPermission);
  const location = useLocation();
  console.log('[AppRoute]:', route);

  if (!route.anonymous) {
    if (status !== AUTH_STATUS.SUCCEEDED) {
      let from = location.search
        ? `${location.pathname}?${location.search}`
        : location?.pathname;
      return <Redirect to={{ pathname: `/signin`, state: { from } }} />;
    } else if (route.auth) {
      if (
        perms.length > 1 &&
        !perms.includes('*') &&
        !perms.includes(route.auth)
      ) {
        return <HttpError layout={LayoutDefault} code={403} />;
      }
    }
  }
  const subroutes = {};
  if (route.routes && route.routes.length > 0) {
    subroutes.routes = route.routes;
    subroutes.menu = route.menu;
    subroutes.path = route.path;
  }

  return (
    <Route>
      {route.layout === false ? (
        <route.component {...subroutes} />
      ) : route.layout ? (
        <route.layout menu={route.menu}>
          <route.component {...subroutes} />
        </route.layout>
      ) : (
        <LayoutDefault menu={route.menu}>
          <route.component {...subroutes} />
        </LayoutDefault>
      )}
    </Route>
  );
}

function Fallback() {
  const intl = useIntl();
  return (
    <div className="fallback-wrapper">
      <Spin
        tip={intl.formatMessage({ id: 'common.loading' })}
        size="large"
      ></Spin>
    </div>
  );
}

// function usePageViews() {
//   let location = useLocation();
//   React.useEffect(() => {
//     console.log('[usePageViews]: ', location.pathname, location);
//   }, [location]);
// }

function AppSwitch() {
  //usePageViews();
  return (
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          render={() => <AppRoute route={route} />}
        />
      ))}
      <Route exact path="/" component={RedirectDefault} />
      <Route
        path="*"
        component={() => <HttpError layout={LayoutDefault} code={404} />}
      />
    </Switch>
  );
}

export default function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<Fallback />}>
        <AppSwitch />
      </Suspense>
    </Router>
  );
}
