import axios from 'axios';
import http from './http';
import { md5 } from '@/utils/md5';
import { notification } from 'antd';

const localStorageRefreshTokenKey = 'dpes.auth.refreshtoken';
const localStorageTokenKey = 'dpes.auth.token';

export function removeRefreshToken() {
  return window.localStorage.removeItem(localStorageRefreshTokenKey);
}
export function getRefreshToken() {
  return window.localStorage.getItem(localStorageRefreshTokenKey);
}
export function setRefreshToken(token) {
  window.localStorage.setItem(localStorageRefreshTokenKey, token);
}

export function removeAuthToken() {
  return window.localStorage.removeItem(localStorageTokenKey);
}
export function getAuthToken() {
  return window.localStorage.getItem(localStorageTokenKey);
}
export function setAuthToken(token) {
  window.localStorage.setItem(localStorageTokenKey, token);
}

export function authorizeCode(code, redirect_uri) {
  const data = {
    grant_type: 'authorization_code',
    code,
    redirect_uri,
  };
  return getToken(data);
}
export function refreshToken(refresh_token) {
  const data = {
    grant_type: 'refresh_token',
    refresh_token,
  };
  return getToken(data);
}

function getToken(data) {
  var auth_token = getAuthToken();
  if (auth_token && data.grant_type === 'refresh_token') {
    data.access_token = auth_token.substring(7);
  }

  data.client_id = 'web';
  data.timestamp = parseInt(new Date().getTime() / 1000);
  data.client_secret = getSecret(data.timestamp);
  return axios
    .post(window.ENV_CFG.token, data)
    .then(res => {
      if (res.data?.access_token) {
        http.setAuthorization(res.data);
        setAuthToken(res.data.token_type + ' ' + res.data.access_token);
        setRefreshToken(res.data.refresh_token);
        return Promise.resolve(res.data);
      } else {
        if (res.data.error) {
          removeAuthToken();
          removeRefreshToken();
          //window.location.reload();
          return Promise.reject(res);
        } else {
          notification.error({
            message: '系统发生异常，请联系IS处理！',
            placement: 'top',
            duration: 0,
          });
          return Promise.reject(res);
        }
        
      }
    })
    .catch(e => {
      return Promise.reject(e);
    });
}

function getSecret(timestamp) {
  const hash = md5('0kVOF1PeA9mg6WZf' + timestamp);
  return hash.slice(8, 16 + (timestamp % 16));
}
