const messages = {
    'b.login': 'Login',
    'b.search': 'Search',
    'b.save': 'Save',
    'b.submit': 'Submit',
    'b.cancel': 'Cancel',
    'b.upload': 'Upload',
    'b.additem': 'Add',
    'b.add': 'Add',
    'b.edit': 'Edit',
    'b.delete': 'Delete',
    'b.yes': 'Yes',
    'b.no': 'No',
    'b.export': 'Export',
    'b.import': 'Import',
    'b.account':'Account',
    'b.payment':'Payment',
    'b.print':'Print',
    'b.reprint':'RePrint',
    'b.printselected':'Print Selected',
    'b.sendtopo':'Send to DPPO',
    'b.uploadsfbilling':'Upload Statement',
    'b.upload':'Upload',
    'b.downloadtemplate':'Download Template',
    'b.selectfile':'Select File',
    'b.getdhlnilling':'Download Statement',
    'b.retry':"Retry"
  };
  export default messages;
  