const messages = {
    'ph.pleseSelect': 'Please select...',
    'ph.pleseInput': 'Please input...',
    'ph.pleseInputEmail': 'Please enter email...',
    'ph.pleseInputRemark': 'Please enter remark...',
    'ph.poRequetNo':'PO Request No.',
    'ph.company':'Company',
    'ph.requestor':'Requestor',
    'ph.carriers':'Carriers',
    'ph.createdstart':'Create Start',
    'ph.createdend':'Create End',
    'ph.trackingno':'Tracking No',
    'ph.invoiceno':'Pre-invoice No.',
    'ph.dnno':'DN No.',
    'ph.status':'Status',
    'ph.warehouse':'Warehouse'
  };
  export default messages;
  